<template>
  <div class="templateEmail">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
        <van-field left-icon="/img/email.png"
          :right-icon="emailValidate ? (emailValidate == 2 ? 'checked' : 'clear') : ''" class="ipt" @input="emailInput"
          v-model="templateValue" :placeholder="$t.emailPage.emailPlaceholder">
        </van-field>
        <div class="ipt-tip text_4 tip-color tl">
          <span v-show="emailValidate == 1">{{ $t.createAccount.tips1 }}</span>
        </div>
        <div class="perfect text_4" v-show="emailValidate == 2">{{ $t.Perfect }}</div>
        <div class="chosen-us flex align-center">
          <van-icon name="star" class="icon" size="24" />
          <span class="flex-auto" v-html="templateData.languageConfig[$language].richText"> </span>
        </div>
        <div class="policy text_4 flex align-center">
          <img src="@img/lock.png" width="20" class="icon" alt="" />
          <span class="flex-auto text_4 theme_02">{{ $t.WeRespect }}
            <span class="link" @click="$utils.routerPush(`/policy-page`, { policyType: $t.menuInfo.links[0].type })">{{
              $t.menuInfo.links[0].name
              }}</span>
          </span>
        </div>
        <div class="btn-warp" v-show="animationLoading && templateData">
          <div @click="emailValidateClick(1), setOptionClickAnimation(-1)"
            :class="{ active: $utils.emailValidate(templateValue), 'shrink-grow-effect': activeIndex === -1 }"
            class="button text_1">
            <van-loading v-if="isLoading" color="" />
            <span v-else>{{ templateData.languageConfig[$language].buttonText }}</span>
          </div>
        </div>

        <div @click="emailValidateClick(2)" class="tip text_3 theme_01 tc  pointer">
          {{ templateData.languageConfig[$language].rejectText }}
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import mainConfig from "@/config/onboarding.config/main"
import { sendTempMail } from "@/api/system"
import { getStatsigExperimentParam } from "@/utils/statsig";
import webConfig from "@/config/web.config/index"
import { setmMxpanelUserInfo } from "@/utils/mixpanel";
export default {
  name: "templateEmailConfirm",
  mixins: [mixinTemplate],
  data() {
    return {
      templateValue: "",
      emailValidate: 0,
      isLoading: false,
      priceParamName: ''
    };
  },
  components: {
    Head,
  },
  async created() {
    this.priceParamName = await getStatsigExperimentParam(webConfig.statsigConfig.priceExperimentName, webConfig.statsigConfig.priceParamName, webConfig.statsigConfig.defaultPriceParam) || webConfig.statsigConfig.defaultPriceParam;
    console.log(this.priceParamName);
    this.$store.commit("updateOnBoardingRecord", {
      key: webConfig.statsigConfig.priceParamName,
      value: this.priceParamName,
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.templateValue) {
        this.emailInput(this.templateValue);

      }
    }, 100);
  },
  methods: {
    emailInput(val) {
      this.emailValidate = val ? (this.$utils.emailValidate(val) ? 2 : 1) : 0;
    },
    emailValidateClick(type) {
      if (this.templateValue && this.emailValidate == 2) {
        type == 1 ? this.sendEmail() : this.nextFn(false);
      } else {
        this.emailValidate = 1;
      }
    },
    //发送邮箱
    async sendEmail() {
      let mixpanelSessionId = window.sessionStorage.getItem('mixpanelDeviceId'); //优先读取缓存
      this.isLoading = true;
      const urlParams = this.$utils.getUrlParams(window.location.href);
      let urlQuery = JSON.parse(window.sessionStorage.getItem('urlQuery')) || {};
      urlQuery = Object.assign(urlParams, urlQuery);
      urlQuery.uid = this.$store.state.onBoardingRecordInfo.uid;
      urlQuery.mixpanelDeviceId = mixpanelSessionId;
      urlQuery[webConfig.statsigConfig.priceParamName] = this.priceParamName;
      let path = '/paywall-page'
      // 将 params 转换为 URL 查询参数
      let queryString = new URLSearchParams(urlQuery).toString();
      // 将查询参数附加到路径
      path = `${path}?${queryString}`;
      let weightUnit = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('weightUnit')] ? 'lbs' : 'kg'
      let data = {
        data: {
          //兼容oog120：age weightBefore weightAfter payLink
          //兼容00g104 :pay_link_url download_link_url privacy_link_url
          age: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('ageScreen')], //年龄
          weightBefore: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')] + ' ' + weightUnit, //当前体重
          weightAfter: this.$store.state.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')] + ' ' + weightUnit, //目标体重
          payLink: window.location.origin + path, //邮箱跳回网站地址
          pay_link_url: window.location.origin + path, //邮箱跳回网站地址  
          download_link_url: mainConfig.iosDeepLinkUrl, //下载地址
          privacy_link_url: window.location.origin + '/policy-page?policyType=privacyPolicy', //隐私政策地址
          uid: this.$store.state.onBoardingRecordInfo.uid,
          discount_percentage: this.$utils.getDiscount('discount'),//价格折扣
        },
        dest: this.templateValue,//邮箱地址
        type: 1,//	type, 1->link 2->createAccount 3->paySuccess
      }
      await sendTempMail(data);
      this.isLoading = false;
      this.nextFn(true);
    },
    // 下一步
    nextFn(value) {
      //设置用户属性--邮箱地址
      setmMxpanelUserInfo({
        $email: this.templateValue
      })
      this.$store.commit("updateOnBoardingRecord", {
        key: "emailConfirmationScreen",
        value: value,
      });
      this.selectOption(this.templateValue)
    }
  },
};
</script>
<style scoped lang="scss">
.templateEmail {

  .btn-warp {
    position: relative !important;
    padding: 20px 0 0 0 !important;
  }

  .ipt {
    margin-top: 32px;
    padding: 15px;
    height: 58px;
    line-height: 58px;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    background: var(--theme_03);

    /deep/.van-field__control {
      font-size: 15px;
      color: var(--theme_01);
    }
  }

  .ipt-tip {
    margin-top: 5px;
  }

  .van-cell {
    color: var(--theme_02);
  }

  /deep/.van-field__left-icon {
    margin-top: 5px;
  }

  /deep/.van-field__body {
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
  }

  /deep/.van-icon-envelop-o {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: var(--theme_02);
  }

  /deep/.van-icon-clear {
    font-size: 24px;
    color: rgba(218, 75, 75, 1);
  }

  /deep/.van-icon-checked {
    font-size: 24px;
    color: #26c934;
  }

  .perfect {
    color: #57d01f;
  }

  .chosen-us {
    margin-top: 40px;
    padding: 12px 10px;
    border-radius: 12px;
    background: #e2ebee;
    font-size: 15px;
    font-weight: 600;
  }

  .icon {
    margin-right: 10px;
    color: #5e9e90;
  }

  .policy {
    margin-top: 10px;
    padding: 12px 10px;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .tip {
    padding: 20px 0;
    text-decoration: underline;
  }
}
</style>
