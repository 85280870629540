import webConfig from "@/config/web.config";
import utils from "./utils";
// 文档地址：https://developers.facebook.com/docs/meta-pixel/get-started/

//挂载在main.js进行初始化
initializeFacebookPixel()
function initializeFacebookPixel() {

    // 获取URL参数
    const params = new Map(location.search.replace('?', '').split('&').map(i => i.split('=')));
    // 加载Facebook Pixel脚本
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // 获取 externalId 并检查白名单
    let externalId = params.get("external_id") || webConfig.defaultFacebookPixelId;
    if (!webConfig.facebookPixelWhiteList.includes(externalId)) {
        externalId = webConfig.defaultFacebookPixelId;
    }
    // 初始化并跟踪 PageView
    fbq('init', externalId);
    fbq('track', 'PageView');
}


// 生成随机FbqEventId
export function getFbqEventId(type = 2) {

    // type:1.Purchase 2.trackCustom
    let eventId = 'event_id_' + utils.formatTimestamp(new Date());
    if (type == 1) {
        let oldEventId = window.localStorage.getItem('fbqEventId');
        oldEventId ? eventId = oldEventId : window.localStorage.setItem("fbqEventId", eventId)
    }
    return eventId
}
//设置meta_pixels自定义事件
export function setFbqTrackCustom(eventName, fbqInfo = {}) {
    fbqInfo.eventID = getFbqEventId();
    fbq('trackCustom', webConfig.fbqName + eventName, fbqInfo);
}
//Purchase标准事件
export function setFbqTrackPurchase(fbqInfo = {}) {
    fbqInfo.eventID = getFbqEventId(2);
    fbq('track', 'Purchase', fbqInfo);
}

