<template>
  <div class="templateAgeGroup ">

    <Head :isHideTitle="true"></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation  "
        :class="{ 'age-group-test': ageGroupDesignParam === webConfig.statsigConfig.selectAgeGroupDesignParam }">
        <div class="heading_1 title tc"
          v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
        <div class="title_2 sub-title tc"
          v-html="ageGroupDesignParam === webConfig.statsigConfig.defaultAgeGroupDesignParam ? templateData.languageConfig[$language].subTitle : templateData.languageConfig[$language].subTitleTest">
        </div>
        <div class="text_1 tc tips" v-html="templateData.languageConfig[$language].tips"></div>
        <div class="option-group  ">
          <div class="option-item" @click="selectAge(item.originName, index)"
            :class="{ right: index % 2 != 0, 'shrink-grow-effect': activeIndex === index }"
            v-for="(item, index) in templateData.languageConfig[$language].optionList">
            <div class="img-warp">
              <div class="option tc text_1">
                <span v-html="item.option"></span>
                <van-icon class="icon" name="arrow" color="#fff" />
              </div>
              <div class="option-img-warp">
                <div class="option-img-border">
                  <img class="option-img" v-if="ageGroupParam"
                    :src="require(`@img/template/${ageGroupParam}_${index + 1}.webp`)" alt="">
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="text_3 tc pointer">
          <span v-html="templateData.languageConfig[$language].mainText"></span>
          <span @click="$utils.routerPush(`/policy-page`, { policyType: 'termsOfService' })" class="link-item">{{
            templateData.languageConfig[$language].linkText1 }}</span>&
          <span @click="$utils.routerPush(`/policy-page`, { policyType: 'privacyPolicy' })" class="link-item">{{
            templateData.languageConfig[$language].linkText2 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import mixinTemplate from "@/mixin/index";
import Head from "./components/head.vue"
import { setGtag } from "@/utils/ga4"
import { setFbqTrackCustom } from "@/utils/facebookPixel"
import { setmMxpanelValue, setmMxpanelUserInfo } from "@/utils/mixpanel";
import { getStatsigExperimentParam } from "@/utils/statsig";
import webConfig from "@/config/web.config/index"
import { sendStatsigEvent } from "@/utils/statsig";
import {
  addFirestoreData,
  signOutWithEmail,
  createAnonymousAccount
} from "@/utils/firebase";
import { Toast } from "vant";
export default {
  name: "templateAgeGroup",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      toast: null,
      ageGroupParam: 'age_group_2',
      webConfig: webConfig,
      ageGroupDesignParam: "test",
    }
  },
  async created() {
    // 图片实验
    // this.ageGroupParam = await getStatsigExperimentParam(webConfig.statsigConfig.ageGroupExperimentName, webConfig.statsigConfig.ageGroupParamName, webConfig.statsigConfig.defaultAgeGroupParam) || webConfig.statsigConfig.defaultAgeGroupParam;
    // console.log(this.ageGroupParam);
    // setmMxpanelUserInfo({
    //   'Experiment Name': webConfig.statsigConfig.ageGroupExperimentName,
    //   Parameters: [this.ageGroupParam]
    // })
    //页面布局实验
    // this.ageGroupDesignParam = await getStatsigExperimentParam(webConfig.statsigConfig.ageGroupDesignExperimentName, webConfig.statsigConfig.ageGroupDesignParamName, webConfig.statsigConfig.defaultAgeGroupDesignParam) || webConfig.statsigConfig.defaultAgeGroupDesignParam;
    // console.log(this.ageGroupDesignParam);
    // setmMxpanelUserInfo({
    //   'Experiment Name': webConfig.statsigConfig.ageGroupDesignParamName,
    //   Parameters: [this.ageGroupDesignParam]
    // })

  },
  mounted() {
    signOutWithEmail()//退出匿名账号
    setGtag("Web Onboarding Started")
    this.setmMxpanelValue(webConfig.mixpanelConfig.onboardingEventKey, 'Started');
    setFbqTrackCustom('Onboarding Started');

  },
  methods: {
    selectAge(item, index) {
      this.setmMxpanelValue(webConfig.mixpanelConfig.onboardingEventKey, 'AgeGroupScreen Clicked');
      this.setOptionClickAnimation(index);
      this.getAnonymousAccount(item)//创建匿名账号
    },
    async getAnonymousAccount(item) {
      this.toast = Toast.loading({
        duration: 0,
        forbidClick: true
      });
      let uid = await createAnonymousAccount();

      //存储uid
      if (uid) {
        this.$store.commit("updateOnBoardingRecord", {
          key: "uid",
          value: uid,
          noSet: true, //不需要存入数据
        });
        if (this.$store.state.onBoardingRecordInfo.uid) {
          let onBoardingRecordInfo = this.$store.state.onBoardingRecordInfo;
          onBoardingRecordInfo.workoutSpend = '15 - 20 minutes';
          onBoardingRecordInfo.uid = uid;
          onBoardingRecordInfo[webConfig.statsigConfig.ageGroupParamName] = this.ageGroupParam;
          await addFirestoreData(uid, onBoardingRecordInfo);//添加firebae数据
          this.toast.clear();
          this.selectOption(item);
          sendStatsigEvent("AgeGroupScreen", webConfig.statsigConfig.ageGroupParamName);
        } else {
          this.getAnonymousAccount();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.templateAgeGroup {

  .link-item {
    margin: 0 5px;
    text-decoration: underline;
    color: var(--theme_01);
  }

  .pointer {
    margin-top: 10px;
  }

  .option-group {
    margin: 20px 0 8px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    .option-item {
      margin-bottom: 10px;
      padding: 0 !important;
      box-sizing: border-box;
      background: none !important;
      border: none !important;
      aspect-ratio: 1;

      .img-warp {
        position: relative;
        width: 100%;
        height: 100%;


        .option {
          position: absolute;
          min-height: auto !important;
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          height: 56px;
          line-height: 56px;
          color: var(--theme_03);
          background: var(--mainColor);
          border-radius: 999px;
          z-index: 9;

          .icon {
            font-size: 15px;
            margin-left: 8px;
            color: var(--theme_03);
          }
        }

        .option-img-warp {
          width: 100%;
          height: 100%;

          .option-img-border {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background: #EDEFF5;
            border: 2px solid #E4E4E4;
            z-index: 0;
          }

          .option-img {
            width: 100%;
            position: relative;
            top: -15px;
            z-index: 1;
            transition: transform .6s;
          }

          .option-img:hover {
            transform: scale(1.05);
          }

        }
      }



    }

  }
}

@media (max-width: 1024px) {
  .templateAgeGroup {
    .title {
      margin-top: 0 !important;
    }

    .sub-title {
      margin: 0 !important;
    }

    .animation {
      max-width: 520px !important;
    }

    .option-group {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      .option-item .img-warp .option {
        height: 51px;
        line-height: 51px;
      }

    }
  }
}

@media (max-width: 576px) {


  .templateAgeGroup {

    .title,
    .sub-title {
      margin-top: 0;
    }


    .option-group {
      gap: 16px;

      .option-item .img-warp .option {
        height: 44px;
        line-height: 44px;
      }
    }
  }






}

//A/B测试
.templateAgeGroup {
  .age-group-test {
    .tips {
      display: none;
    }

    .pointer {
      margin-top: 24px;
    }

    .option-group {
      margin-top: 20px;

      .option-item {
        margin-bottom: 10px;

        .img-warp {
          .option-img-warp {
            .option-img-border {
              background: var(--theme_03);
              border: none;
              border-radius: 15px;
            }

            .option-img {
              top: 2px;
            }
          }

          .option {
            padding: 0 9px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0 0 15px 15px;
            bottom: -10px;

            .van-icon-arrow {
              background-color: var(--theme_03);
              border-radius: 50%;
              padding: 3px;
              color: var(--mainColor) !important;
              font-weight: 900;
            }

          }
        }
      }
    }
  }
}

@media (max-width: 330px) {
  .templateAgeGroup .age-group-test .option-group .option-item .img-warp .option {
    padding: 0 5px;
    font-size: 14px;
  }
}
</style>