// 文档地址：https://docs.statsig.com/client/javascript-sdk
import { StatsigClient } from '@statsig/js-client';
import webConfig from "@/config/web.config";
import store from '@/store';
import utils from '@/utils/utils';
// 初始化Statsig
export async function initStatsig() {
    let userID = window.localStorage.getItem('statsigUserID') || utils.generateUUID();
    window.localStorage.setItem('statsigUserID', userID);
    const statsigClient = new StatsigClient(
        webConfig.statsigConfig.statsigClientKey,
        { userID },
        { environment: { tier: webConfig.statsigConfig.tier } }
    );
    try {
        // 初始化并等待获取最新的值
        await statsigClient.initializeAsync();
        store.commit("setStatsigClient", statsigClient);
        return statsigClient
    } catch (error) {
        throw Error('Failed to initialize Statsig Client', error);

    }
}
// 获取参数
export async function getStatsigExperimentParam(experimentName, paramName, defaultValue = webConfig.statsigConfig.defaultValue) {
    console.log(store.getters.getStatsigClient);

    try {
        if (store.getters.getStatsigClient) {

            console.log('初始化成功');

            const experiment = store.state.statsigClient.getExperiment(experimentName);
            return experiment.get(paramName, defaultValue);
        } else {
            throw Error('Statsig Client is not initialized');
        }

    } catch (error) {
        console.error(`Failed to get experiment param for ${experimentName}`, error);
        return defaultValue; // 如果出现错误，返回默认值
    }
}


//发送事件
export async function sendStatsigEvent(eventName, value, metadata = {}) {
    eventName = `OB ${eventName} Web`
    try {
        if (store.getters.getStatsigClient) {
            store.getters.getStatsigClient.logEvent({
                eventName,
                value,
                metadata,
            });
        } else {
            throw Error('Statsig Client is not initialized');
        }

    } catch (error) {
        console.error(`Failed to log event "${eventName}"`, error);
    }
}

